<template>
  <div class="content-wrapper__layout">
    <side-nav></side-nav>

    <div class="content-wrapper__main-content-wrapper content-wrapper__main-content-wrapper--31">
      <section class="main-content">
        <section class="section">
          <div class="box box--no-shadow">
            <form class="form" enctype="multipart/form-data">
              <fieldset class="form__set">
                <legend class="form__legend">Evento</legend>
                <div class="form__group">
                  <label class="form__label" for="event-title">Nombre del evento</label>
                  <input type="text" class="form__input" id="event-title" v-model.trim="event.title" placeholder="Ingresa el nombre del evento" required>
                </div>
                <div class="form__group">
                  <label class="form__label" for="event-description">Descripción del evento <small class="text-alt">(Máximo 100 caracteres)</small></label>
                  <input type="text" class="form__input" id="event-description" v-model.trim="event.description" placeholder="Añade una pequeña descripción a tu evento" required>
                </div>
                <div class="form__group">
                  <label class="form__label" for="event-info">Información del evento</label>
                  <RichEditor :options="options" @content="setEditorContent"></RichEditor>
                </div>
                <div class="form__group">
                  <label class="form__label" for="event-urlEvent">Enlace externo <small class="text-alt">(Opcional)</small></label>
                  <input type="text" class="form__input" id="event-urlEvent" v-model.trim="event.urlEvent" placeholder="Ingresa un enlace">
                </div>

                <div class="form__group">
                  <div class="grid grid-2">
                    <div>
                      <label class="form__label" for="event-file">Imagen de portada <small class="text-alt">(Opcional)</small></label>
                      <input ref="inputImage" type="file" class="form__input" accept="image/jpg , image/jpeg , image/png" id="event-file" @change="attachFile($event, 'image')">
                      <div class="text-right" v-if="event.image">
                        <button class="btn btn--danger btn--xs" type="button" @click.prevent="removeFile('Image')">Eliminar archivo</button>
                      </div>
                    </div>
                    <div>
                      <label class="form__label" for="event-file">Archivo adjunto <small class="text-alt">(Opcional)</small></label>
                      <input ref="inputFile" type="file" class="form__input" accept="image/jpg , image/jpeg , image/png , application/pdf" id="event-file" @change="attachFile($event, 'file')">
                      <div class="text-right" v-if="event.file">
                        <button class="btn btn--danger btn--xs" type="button" @click.prevent="removeFile('File')">Eliminar archivo</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid grid-2">
                  <div class="form__group">
                    <label class="form__label" id="event-causes">Causas <small class="text-alt">(3 máximo)</small></label>
                    <Multiselect
                      v-model="event.causes"
                      :mode="'multiple'"
                      :hideSelected="false"
                      :required="false"
                      :options="causes"
                      valueProp="id"
                      :searchable="true"
                      :noResultsText="'No se encontraron resultados'"
                      :closeOnSelect="false"
                      :max="3"
                      :placeholder="'Seleccione'"
                      ref="causes"
                    >
                      <template v-slot:multiplelabel="{ values }">
                        <div class="multiselect-multiple-label">
                          {{ `${values.length} ${values.length > 1 ? 'causas seleccionadas' : 'causa seleccionada'} `}}
                        </div>
                      </template>
                      <template v-slot:option="{ option }">
                        {{option.name}}<span class="tag tag--primary tag--pull--right post__tag">{{option.total}}</span>
                      </template>
                    </Multiselect>
                  </div>
                  <div class="form__group">
                    <label class="form__label" id="event-areas">Áreas de interés <small class="text-alt">(3 máximo)</small></label>
                      <Multiselect
                        v-model="event.areas"
                        :mode="'multiple'"
                        :hideSelected="false"
                        :required="false"
                        :options="areas"
                        valueProp="id"
                        :searchable="true"
                        :noResultsText="'No se encontraron resultados'"
                        :closeOnSelect="false"
                        :max="3"
                        :placeholder="'Seleccione'"
                        ref="areas"
                      >
                        <template v-slot:multiplelabel="{ values }">
                          <div class="multiselect-multiple-label">
                            {{ `${values.length} ${values.length > 1 ? 'áreas de interés seleccionadas' : 'área de interés seleccionada'} `}}
                          </div>
                        </template>
                        <template v-slot:option="{ option }">
                          {{option.name}}<span class="tag tag--primary tag--pull--right post__tag">{{option.total}}</span>
                        </template>
                      </Multiselect>
                  </div>
                </div>
                <div class="form__group">
                  <label class="form__label" id="event-tags" for="post_tags">Etiquetas <small class="text-alt">(Opcional)</small> </label>
                    <Multiselect
                      id="event-tags"
                      v-model="event.tags"
                      :required="false"
                      label="name"
                      valueProp="id"
                      :searchable="true"
                      :noResultsText="'No se encontraron resultados'"
                      :mode="'tags'"
                      :createTag="true"
                      :appendNewOption="true"
                      :max="5"
                      :closeOnSelect="false"
                      :hideSelected="false"
                      :placeholder="'Comienza a escribir para agregar etiquetas'"
                      :min-chars="1"
                      :resolve-on-load="true"
                      :delay="100"
                      :options="tagList"
                      @search-change="async (query) => {
                        return await fetchTags(query)
                      }"
                      ref="tags"
                      >
                      <template v-slot:tag="{ option, handleTagRemove, disabled }">
                        <div class="multiselect-tag is-user" :style="[checkHex(option.hex) ? {'background': option.hex} : {'background': colors[tagHolder.indexOf(option.id)]}]">
                          {{ option.name }}
                          <span
                            v-if="!disabled"
                            class="multiselect-tag-remove"
                            @mousedown.prevent="handleTagRemove(option, $event)"
                          >
                            <span class="multiselect-tag-remove-icon"></span>
                          </span>
                        </div>
                      </template>
                    </Multiselect> 
                </div>
              </fieldset>
              <fieldset class="form__set">
                <legend class="form__legend">Sobre el evento</legend>
                <div class="grid grid-2">
                  <div class="form__group">
                    <label class="form__label" for="event-date">Fecha del evento</label>
                    <input type="date" class="form__input" id="event-date" :min="this.$moment().format('YYYY-MM-DD')" v-model="event.date" placeholder="Selecciona una fecha" required>
                  </div>
                  <div class="form__group">
                    <label class="form__label" for="event-time">Hora de inicio</label>
                    <input type="time" class="form__input" id="event-time" v-model="event.time" placeholder="Selecciona una hora para tu evento" required>
                  </div>
                </div>
                <div class="grid grid-2">
                  <div class="form__group">
                    <label class="form__label">Tipo de evento</label>
                    <div class="grid grid-2">
                      <div>
                        <input id="onSite1" type="radio" :value="true" v-model="event.onSite">
                        <label for="onSite1">
                          Presencial
                        </label>
                      </div>
                      <div>
                        <input id="onSite2" type="radio" :value="false" v-model="event.onSite">
                        <label for="onSite2">
                          Digital
                      </label>
                    </div>
                    </div>
                  </div>
                </div>
                <template v-if="event.onSite">
                  <div class="grid grid-2">
                    <div class="form__group">
                      <label class="form__label" for="event-region">Región</label> 
                      <select class="form__input" id="event-region" v-model="event.location.region" @change="changeRegion()" required>
                        <option :value="null" selected disabled >Seleccionar Región</option>
                        <option v-for="region in regions" :key="region" :value="region.id">{{region.name}}</option>
                      </select>
                    </div>
                    <div class="form__group">
                      <label class="form__label" for="event-comuna">Comuna</label>
                      <select class="form__input" id="event-comuna" v-model="event.location.comuna" required>
                        <option :value="null" selected disabled>Seleccionar Comuna</option>
                        <option v-for="comuna in comunas" :key="comuna" :value="comuna.id">{{comuna.name}}</option>
                      </select>  
                    </div>
                  </div>
                  <div class="form__group">
                    <label class="form__label" for="event-address">Dirección / Ubicación</label>
                    <input type="" class="form__input" id="event-address" v-model="event.location.address" required>
                  </div>
                </template>
                <template v-else>
                  <div class="form__group">
                    <label class="form__label" for="event-address">Enlace</label>
                    <input type="" class="form__input" id="event-address" v-model="event.virtualUrl" required placeholder="Ej: https://us05web.zoom.us/j/23..." >
                  </div>
                </template>
              </fieldset>
              <fieldset class="form__set">
                <legend class="form__legend">Contacto</legend>
                <div class="form__group">
                  <label class="form__label" for="post-title">Correo electrónico para más información <small class="text-alt">(Opcional)</small> </label>
                  <input type="email" class="form__input" id="post-title" v-model="event.email" placeholder="ejemplo@ejemplo.com">
                </div>
                <div class="form__group">
                  <label class="form__label" for="post-title">Teléfono <small class="text-alt">(Opcional)</small></label>
                  <input type="phone" class="form__input" id="post-title" v-model="event.phone">
                </div>
                <div class="form__group">
                  <label class="form__label" for="post-title">Facebook <small class="text-alt">(Opcional)</small></label>
                  <input type="text" class="form__input" id="post-title" v-model="event.facebook" placeholder="http://www.facebook.com/pagina">
                </div>
                <div class="form__group">
                  <label class="form__label" for="post-title">Twitter <small class="text-alt">(Opcional)</small></label>
                  <input type="text" class="form__input" id="post-title" v-model="event.twitter" placeholder="@evento">
                </div>
                <div class="form__group">
                  <label class="form__label" for="post-title">Instagram <small class="text-alt">(Opcional)</small></label>
                  <input type="text" class="form__input" id="post-title" v-model="event.instagram" placeholder="@evento">
                </div>
              </fieldset>
              <div class="form__actions">
                <button class="btn btn--primary" :disabled="submitting" @click.prevent="validateForm()">Crear evento</button>
              </div>
            </form>
          </div>
        </section>
      </section>
    </div>
    <modal-dialog v-if="showPreview" @close="showPreview = false">
      <template #title>Previsualización del evento</template>
      <template #description>Así lucirá el evento una vez publicada, verifica que esté todo bien antes de confirmar</template>
      <template #content>
        <div class="post-wrapper">
          <div class="post">
            <div class="post__header">
              <div class="post__author">
                <div class="user__image" v-if="$store.state.user.profile_image">
                  <img referrerpolicy="no-referrer" :src="$store.state.user.profile_image">
                </div>
                <div class="user__image" v-else>
                  <img src="@/assets/images/avatar_default.png">
                </div>
                <div class="user__data">
                  <h5>
                    {{ $store.state.user.name }} {{$store.state.user.lastnameA}} {{$store.state.user.lastnameB}}
                  </h5>
                  <p class="post__time caption text-alt" :title="$moment.utc().local().format('L')">
                    {{$moment.utc(event.createdAt).fromNow()}}
                  </p>
                </div>
              </div>
            </div>
            <div class="post__main-content">
              <div class="post__causes-and-areas">
                <img src="@/assets/images/icons/ico_book--gray.svg">
                  <span v-for="cause in $refs.causes?.iv" :key="cause.id" class="caption post__cause">{{cause.name}}</span>
                  <span v-for="area in $refs.areas?.iv" :key="area.id" class="caption post__cause">{{area.name}}</span>
              </div>
              <div class="post__title">
                <h4>{{event.title}}</h4>
                <br>
                <p class="post__description">
                  {{ event.description }}
                </p>
              </div>
              <div v-if="$refs.tags?.iv" class="post__tags">
                <span class="tag tag--primary post__tag" v-for="(tag,i) in $refs.tags.iv" :key="i">
                  {{tag.name}}
                </span>
              </div>
              <div class="post__cover-image" v-if="selectedInputs.image">
                <img referrerpolicy="no-referrer" :src="selectedInputs.image">
                <br><br>
              </div>
              <div class="post__content" v-html="event.information">
              </div>
              <div class="post__url" v-if="event.urlEvent">
                <h5 class="post__url-title">Enlace externo</h5>
                <div class="post__url-info">
                  <img class="post__url-icon" src="@/assets/images/icons/ico_external-link--black.svg">
                  <a :href="event.urlEvent" target="_blank">{{ event.urlEvent }}</a>
                </div>
              </div>
              <div class="post__download" v-if="selectedInputs.file">
                <a class="btn btn--primary" :href="selectedInputs.file" target="_blank"><img class="icon" src="@/assets/images/icons/ico_download--white.svg"> Descargar archivo adjunto</a>
              </div>
              <h5 class="text-alt">Más información</h5>
              <br>
              <div class="post__meta">
                <table class="table-list">
                  <tr>
                    <td><p>Fecha del evento:</p></td>
                    <td class="text-right">{{ this.$moment(event.date).format('DD') }} de {{this.$moment(event.date).format('MMMM')}} del {{this.$moment(event.date).format('YYYY')}} </td>
                  </tr>
                  <tr>
                    <td><p>Hora de inicio:</p></td>
                    <td class="text-right">{{ event.time }}</td>
                  </tr>
                  <template v-if="event.onSite">
                    <tr>
                      <td><p>Region:</p></td>
                      <td class="text-right">{{ regions.find(item => item.id === event.location.region).name }}</td>
                    </tr>
                    <tr>
                      <td><p>Comuna:</p></td>
                      <td class="text-right">{{ comunas.find(item => item.id === event.location.comuna).name }}</td>
                    </tr>
                    <tr>
                      <td><p>Dirección / Ubicación:</p></td>
                      <td class="text-right">{{ event.location.address }}</td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td><p>Enlace:</p></td>
                    <td class="text-right" v-if="$store.state.user.id">
                      <a target="_blank" :href="event.virtualUrl">Ir al enlace</a>
                    </td>
                  </tr>
                  <tr v-if="event.email">
                    <td><p>Correo de contacto:</p></td>
                    <td class="text-right">{{ event.email }}</td>
                  </tr>
                  <tr v-if="event.phone">
                    <td><p>Teléfono de contacto:</p></td>
                    <td class="text-right">{{ event.phone }}</td>
                  </tr>
                  <tr v-if="event.facebook">
                    <td><p>Facebook:</p></td>
                    <td class="text-right"><a :href="event.facebook" target="_blank">Ir a Facebook</a></td>
                  </tr>
                  <tr v-if="event.twitter">
                    <td><p>Twitter:</p></td>
                    <td class="text-right"><a :href="`https://www.twitter.com/${ event.twitter }`" target="_blank">Ir a Twitter</a></td>
                  </tr>
                  <tr v-if="event.instagram">
                    <td><p>Instagram:</p></td>
                    <td class="text-right"><a :href="`http://www.instagram.com/${ event.instagram }`" target="_blank">Ir a instagram</a></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
          <div class="form__actions">
            <button class="btn btn--primary btn--outline" type="button" @click.prevent="showPreview = false" :disabled="submitting">Volver a editar</button>
            <button class="btn btn--primary" type="button" :disabled="submitting" @click.prevent="saveEvent()">{{submitting ? 'Guardando...' : 'Confirmar'}}</button>
          </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import SideNav from '@/components/SideNav.vue'
import Multiselect from '@vueform/multiselect'
import RichEditor from '@/components/RichEditor.vue'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  name: 'NewEvent',
  components: { SideNav , Multiselect , RichEditor , ModalDialog},
  data () {
    return {
      submitting: false,
      colors: ['#DAF7A6', '#FFC300', '##FF5733', '#16A085', '#34495E'],
      event: {
        title: null,
        description: null,
        information: null,
        date: null,
        time: null,
        location: {
          region: null,
          comuna: null,
          address: null
        },
        email: null,
        phone: null,
        facebook: null,
        twitter: null,
        instagram: null,
        areas: [],
        causes: [],
        tags: [],
        file: null,
        fileType: null,
        urlEvent: '',
        onSite: true,
        image: null,
        imageType: null
      },
      options: {
        placeholder: "Escribe aquí...",
        theme: 'snow',
        modules: {
          toolbar: {
            container: [
              [
                'bold', 'italic', 'underline',
                { align: '' }, { align: 'center' },{ align: 'right' }, { align: 'justify' },
                { 'list': 'ordered' }, { 'list': 'bullet' },'video'
                //'image'
              ],
            ],
            /*handlers: {
              image: imageHandler
            },*/

          }
        }
      },
      areas: {},
      causes: {},
      tags: {},
      tagHolder: [],
      tagList: [],
      regions: {},
      comunas: {},
      extensions:{
        png: 'image/png',
        jpeg: 'image/jpeg',
        jpg: 'image/jpeg',
        pdf: 'application/pdf'
      },
      showPreview: false,
      selectedInputs: {
        file: null,
        image: null
      }
    }
  },
  async mounted() {
    this.areas = ((await this.$axios.get(`areas`)).data).sort((a , b) => b.total - a.total)
    this.causes = ((await this.$axios.get('causas')).data).sort((a , b) => b.total - a.total)
    this.tags = (await this.$axios.get('tags/popular')).data
    this.regions = (await this.$axios.get('Event/region')).data
  },
  methods:{
    removeFile(el){
      this.$refs['input' + el].value = null
      this.event[el.toLowerCase()] = null
      this.event[el.toLowerCase() + 'Type'] = null
      this.selectedInputs[el.toLowerCase()] = null
    },
    attachFile(e, el){
      const file = e.target.files[0]
      if(file){
        if(this.extensions[file.name.split('.').pop()]){
          this.getBase64Image(file, el)
          this.selectedInputs[el] = URL.createObjectURL(file)
        }else{
          this.event[el] = null
          this.event[el + 'Type'] = null
          this.selectedInputs[el] = null
          return this.$toast.error("El tipo de archivo seleccionado no esta permitido")
        }
      }else{
        this.selectedInputs[el] = null
        this.event[el] = null
        this.event[el + 'Type'] = null
      }
    },
    getBase64Image(archive, el) {
      var reader = new FileReader()
      reader.onload = () => {
        this.event[el] = (reader.result).replace(`data:${this.extensions[archive.name.split('.').pop()]};base64,` , '')
        this.event[el + 'Type'] = archive.name.split('.').pop()
      }
      reader.onerror = (error) => {
        console.log('Error: ', error)
      }
      reader.readAsDataURL(archive)
    },
    async changeRegion(){
      this.event.location.comuna = null
      this.comunas = (await this.$axios.get(`Event/comunas/${this.event.location.region}`)).data
    },
    setEditorContent(ed){
      this.event.information = ed
    },
    async fetchTags(query){
      //Corregir error de s.value
      let data = null
      await this.$nextTick()
      if(query == null || query == '')
        data = (await this.$axios.get('tags/popular')).data
      else
        data = (await this.$axios.get(`tags/search-by/${query}`)).data
      this.tagList = data
    },
    checkUrl(string) {
      let givenURL
      try {
        givenURL = new URL(string);
      } catch (error) {
        //console.log("error is",error)
        return false
      }
      return givenURL.protocol === "http:" || givenURL.protocol === "https:"
    },
    checkHex(input) {
      if(input == null) return false
      var check, len
      if(typeof input == 'string') {
        if(input[0] === "#") {
          len = input.length
          if (len === 4 || len === 7) { 
            input = input.toLowerCase()
            check = '#' + ('00000000' + parseInt(input.substr(1), 16).toString(16)).substr(1 - len)
            return check === input
          }
        }
      }
      return false
    },
    validateForm(){
      if(this.checkForm()){
        this.showPreview = true
      }
    },
    checkForm(){
      if(!this.event.title){
        this.$toast.error("Debes agregar un título al evento")
        return false
      }
      if(!this.event.description){
        this.$toast.error("Debes agregar contenido al evento")
        return false
      }
      if(!this.event.information || this.event.information.length == 0 || this.event.information == "<p><br></p>"){
        this.$toast.error("Debes agregar información al evento")
        return false
      }
      if(this.event.urlEvent !== '' && !this.checkUrl(this.event.urlEvent)){
        this.$toast.error('El enlace no es válido')
        return false
      }
      if(!(this.event.areas.length > 0 || this.event.causes.length > 0)){
        this.$toast.error('Debes seleccionar al menos una causa y/o área de interés')
        return false
      }
      if(!this.event.date){
        this.$toast.error('Debes seleccionar una fecha para el evento')
        return false
      }
      if(!(this.$moment().isSameOrBefore(this.$moment(this.event.date), 'day') && this.$moment().isSameOrBefore(this.$moment(this.event.date), 'month') && this.$moment().isSameOrBefore(this.$moment(this.event.date), 'year'))){
        this.$toast.error('La fecha del evento seleccionada no es válida')
        return false
      }
      if(!this.event.time){
        this.$toast.error('Debes seleccionar una hora para el evento')
        return false
      }
      if(this.event.onSite && !this.event.location.region){
        this.$toast.error('Debes seleccionar una region')
        return false
      }
      if(this.event.onSite && !this.event.location.comuna){
        this.$toast.error('Debes seleccionar una comuna')
        return false
      }
      if(this.event.onSite && !this.event.location.address){
        this.$toast.error('Debes añadir una dirección para este evento')
        return false
      }
      if(!this.event.onSite && !this.event.virtualUrl){
        this.$toast.error('Debes añadir un enlace para esta reunión')
        return false
      }

      return true
    },
    async saveEvent(){
      this.submitting = true
      if(this.checkForm()){
        if(this.event.areas.length > 0 || this.event.causes.length > 0){
          const res = (await this.$axios.post('Event/Create', this.event))      
          if(res.status == 200){
            this.$toast.success('Evento creado con éxito')
            this.$router.push('/eventos')
          }
          else{
            this.$toast.error('Ocurrió un error al crear el evento.')
            this.submitting = false
          }
        }else{
          this.$router.push('#event-causes')
          this.$toast.error('Debes seleccionar al menos una causa y/o área de interés')
        }

      }
      this.submitting = false
    }
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>